import axios from 'axios';
import { observer } from 'mobx-react-lite';
import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { supportedLocales } from '../config';
import { useAuth } from '../providers/AuthProvider';
import {
  executeUrl,
  fileUploadUrl,
  getCodeUrl,
  getSetFavoriteUrl,
  getSupportUrl,
  getTagsUrl,
  getToolInputsUrl,
  getToolsInfoUrl,
  logOutRedirectUrl,
  loginRedirectUrl,
  ordersUrl,
} from '../routes/apiRoutes';
import { toolBoxStore } from '../store/store';

import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import type { ReactNode } from 'react';

interface ServerApiContextProps {
  getLoginRedirectUrl: (backRoute?: string) => string;
  getLogoutRedirectUrl: () => string;
  ngwClientAuthrizeInTool: (toolId: string, clientId: string) => void;
  getToolsList: () => Promise<AxiosResponse>;
  getTagsList: () => Promise<AxiosResponse>;
  getOrders: (config?: AxiosRequestConfig) => Promise<AxiosResponse>;
  getToolInputs: (id: string) => Promise<AxiosResponse>;
  getToolCode: (id: string) => Promise<AxiosResponse>;
  postSetFavorite: (id: string, fav: boolean) => Promise<AxiosResponse>;
  postExecuteTool: (id: string, inputs: any) => Promise<AxiosResponse>;
  postUploadFile: (
    file: any,
    config: AxiosRequestConfig,
  ) => Promise<AxiosResponse>; // any
  postSupport: (
    id: string,
    comment: string,
    config?: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
}

type ServerApiProviderProps = {
  children: ReactNode;
};

const ServerApiProvider: React.FC<ServerApiProviderProps> = observer(
  ({ children }) => {
    const auth = useAuth();
    const { i18n } = useTranslation();

    const locale = supportedLocales.includes(i18n.language)
      ? i18n.language
      : 'en';

    const token = auth.getAuthToken();

    const commonHeaders = token
      ? {
          Authorization: `Token ${token}`,
          'Accept-Language': locale,
        }
      : { 'Accept-Language': locale };

    const getToolsList = async (
      config?: AxiosRequestConfig,
    ): Promise<AxiosResponse> => {
      return axios.get(getToolsInfoUrl(locale), {
        ...config,
        headers: { ...commonHeaders },
      });
    };

    const getTagsList = async (
      config?: AxiosRequestConfig,
    ): Promise<AxiosResponse> => {
      return axios.get(getTagsUrl(locale), {
        ...config,
        headers: { ...commonHeaders },
      });
    };

    const oauthPrefix = toolBoxStore.oauthPrefix;

    const getLoginRedirectUrl = (backRoute: string = '/') => {
      return (
        window.location.origin +
        (oauthPrefix ? `/${oauthPrefix}` : '') +
        loginRedirectUrl +
        backRoute
      );
    };

    const getLogoutRedirectUrl = () => {
      return (
        window.location.origin +
        (oauthPrefix ? `/${oauthPrefix}` : '') +
        logOutRedirectUrl
      );
    };

    const ngwClientAuthrizeInTool = (
      toolId: string,
      clientId: string,
    ): void => {
      window.location.assign(
        `${window.location.origin}${
          oauthPrefix ? `/${oauthPrefix}` : ''
        }/login/?client_id=${clientId}&next=/operation/${toolId}`,
      );
    };

    const getToolInputs = async (
      id: string,
      config?: AxiosRequestConfig,
    ): Promise<AxiosResponse> =>
      axios.get(getToolInputsUrl(id), {
        ...config,
        headers: { ...commonHeaders },
      });

    const getToolCode = async (
      id: string,
      config?: AxiosRequestConfig,
    ): Promise<AxiosResponse> =>
      axios.get(getCodeUrl(id), {
        ...config,
        headers: { ...commonHeaders },
      });

    const postUploadFile = async (
      file: any,
      config?: AxiosRequestConfig,
    ): Promise<AxiosResponse> =>
      axios.post(`${fileUploadUrl}/?filename=${file.name}`, file, {
        ...config,
        headers: { ...commonHeaders },
      });

    const postSetFavorite = async (
      toolId: string,
      fav: boolean,
      config?: AxiosRequestConfig,
    ): Promise<AxiosResponse> => {
      const new_value = fav ? 1 : 0;

      return axios.post(
        getSetFavoriteUrl(toolId),
        { new_value },
        {
          ...config,
          headers: { ...commonHeaders },
        },
      );
    };

    const postSupport = async (
      id: string,
      comment: string,
      config?: AxiosRequestConfig,
    ): Promise<AxiosResponse> => {
      return axios.post(
        getSupportUrl(id),
        { comment },
        {
          ...config,
          headers: { ...commonHeaders },
        },
      );
    };

    const postExecuteTool = async (
      toolId: string,
      inputs: any,
      config?: AxiosRequestConfig,
    ): Promise<AxiosResponse> =>
      axios.post(
        executeUrl,
        {
          operation: toolId,
          inputs: inputs,
          mode: 'ui',
        },
        {
          ...config,
          headers: {
            ...commonHeaders,
            'Content-Type': 'application/json',
          },
        },
      );

    const getOrders = async (
      config?: AxiosRequestConfig,
    ): Promise<AxiosResponse> =>
      axios.get(ordersUrl, {
        ...config,
        headers: { ...commonHeaders },
      });

    const serverAPI: ServerApiContextProps = {
      getLoginRedirectUrl,
      getLogoutRedirectUrl,
      ngwClientAuthrizeInTool,
      getTagsList,
      getToolsList,
      getToolInputs,
      getToolCode,
      postUploadFile,
      postSetFavorite,
      postExecuteTool,
      postSupport,
      getOrders,
    };

    return (
      <ServerApiContext.Provider value={serverAPI}>
        {children}
      </ServerApiContext.Provider>
    );
  },
);

export const ServerApiContext =
  //@ts-ignore
  createContext<ServerApiContextProps>(ServerApiProvider);

export const useApi = () => useContext(ServerApiContext);

export default ServerApiProvider;
