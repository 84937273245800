export const configUrl = '/api/config';
export const ToolInfoUrl = '/api/ru/tools/';
export const fileUploadUrl = '/api/upload';
export const ordersUrl = '/api/orders';
export const executeUrl = '/api/json/execute/';

export const profileUrl = '/api/profile';
export const cloudAuthProfileUrl = 'https://my.nextgis.com/profile';

// dev
export const loginUrl = '/api/auth/remote?username=sirflyingv';
// prod
export const loginRedirectUrl = '/login/?next=';
export const logOutRedirectUrl = '/logout/';
export const tokenUrl = '/api/token';

export const getToolsInfoUrl = (locale: string) => `/api/${locale}/tools/`;
export const getTagsUrl = (locale: string) => `/api/${locale}/tags/`;

export const getToolInputsUrl = (toolId: string) =>
  `/api/operations/${toolId}/inputs/`;

export const getCodeUrl = (toolId: string) => `/api/example/${toolId}`;

export const getSetFavoriteUrl = (toolId: string) =>
  `/api/operations/${toolId}/favorite/`;

export const getSupportUrl = (orderId: string) => `/api/support/${orderId}`;

export const getFileDownloadurl = (fileId: string) => `/api/download/${fileId}`;

export const getTermsUrl = (lang: string) => {
  if (lang === 'ru') {
    return 'http://nextgis.ru/terms/';
  } else {
    return 'http://nextgis.com/terms/';
  }
};

export const getConfUrl = (lang: string) => {
  if (lang === 'ru') {
    return 'http://nextgis.ru/privacy';
  } else {
    return 'http://nextgis.com/privacy';
  }
};

export const getPricingUrl = (lang: string) => {
  if (lang === 'ru') {
    return 'https://nextgis.ru/pricing-base/';
  } else {
    return 'https://nextgis.com/pricing-base/';
  }
};

export const getDocsUrl = (lang: string, operationId: string) =>
  `https://docs.nextgis.${
    lang === 'ru' ? 'ru' : 'com'
  }/docs_toolbox/source/${operationId}.html`;
