import { CloseOutlined } from '@ant-design/icons';
import { notification } from 'antd';

export const showNotification = (
  message: string = '',
  description: string = '',
  icon: typeof CloseOutlined = CloseOutlined,
  color: string = 'red',
) => {
  const IconComp = icon;

  notification.info({
    type: 'error',
    message: message,
    description: description,
    placement: 'topRight',
    icon: <IconComp style={{ color }} />,
  });
};
