import localeEN from 'antd/es/date-picker/locale/en_US';
import localeRU from 'antd/es/date-picker/locale/ru_RU';

import 'dayjs/locale/ru';
import 'dayjs/locale/en-gb';

import type { PickerLocale } from 'antd/es/date-picker/generatePicker';

type localeMap = {
  [key: string]: PickerLocale;
};

const localeMapping: localeMap = {
  ru: localeRU,
  en: localeEN,
};

const getDateComponentLocale = (lang: string) => {
  if (localeMapping[lang]) {
    return localeMapping[lang];
  } else {
    return localeEN;
  }
};

const ddmmyyyyToyyyymmdd = (date: string) => {
  return date.split('-').reverse().join('/');
};

const serializeErrorDescription = (data: string, locale: string) => {
  try {
    const dataAsJson = JSON.parse(data);
    if (dataAsJson.ru && dataAsJson.en) {
      return dataAsJson[locale];
    } else {
      return data;
    }
  } catch (err) {
    console.log(err);
  }
  return data;
};

export {
  getDateComponentLocale,
  ddmmyyyyToyyyymmdd,
  serializeErrorDescription,
};
