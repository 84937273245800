import React from 'react';
import './customIcons.css';

const TelegramIcon: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        width: '18px',
        height: '18px',
      }}
    >
      <svg role="img" className="social-links__icon telegram-icon">
        <use xlinkHref="/static/social_icons.svg#telegram"></use>
      </svg>
    </div>
  );
};

export { TelegramIcon };
