import './footerContent.css';
import {
  GithubOutlined,
  LinkedinFilled,
  MailFilled,
  PhoneFilled,
  TwitterOutlined,
  YoutubeFilled,
} from '@ant-design/icons';
import { Grid } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getConfUrl, getTermsUrl } from '../routes/apiRoutes';

import { TelegramIcon } from './customIcons/Telegram';
import { VKIcon } from './customIcons/VKIcon';

const { useBreakpoint } = Grid;

const ContactsRu: React.FC = () => {
  const screens = useBreakpoint();

  const year = new Date().getFullYear();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '6px',
        flexWrap: 'wrap',
      }}
    >
      <div style={{ whiteSpace: 'nowrap' }}>
        <span>© {year}</span>{' '}
        <a
          className="social-link"
          href="https://nextgis.ru"
          target="_blank"
          rel="noreferrer"
        >
          NextGIS
        </a>
      </div>
      <div style={{ display: 'flex', gap: '6px', flexWrap: 'nowrap' }}>
        <a
          className="social-link"
          href="https://telegram.me/nextgis_chat"
          target="_blank"
          rel="noreferrer"
        >
          <TelegramIcon />
        </a>

        <a
          className="social-link"
          href="https://vk.com/nextgis"
          target="_blank"
          rel="noreferrer"
        >
          <VKIcon />
        </a>
        <a href="https://github.com/nextgis/" target="_blank" rel="noreferrer">
          <GithubOutlined style={{ fontSize: 18 }} />
        </a>
        <a
          href="https://www.youtube.com/@nextgis_ru"
          target="_blank"
          rel="noreferrer"
        >
          <YoutubeFilled style={{ fontSize: 18 }} />
        </a>
        <a className="social-link" href="mailto:info@nextgis.com">
          {!screens.lg ? (
            <MailFilled style={{ fontSize: 18 }} />
          ) : (
            <span>info@nextgis.com</span>
          )}
        </a>
        <a className="social-link" href="tel:+79687305252">
          {!screens.lg ? (
            <PhoneFilled style={{ fontSize: 18 }} />
          ) : (
            <span> {`+7 (968) 730-52-52`}</span>
          )}
        </a>
      </div>
    </div>
  );
};

const ContactsEn: React.FC = () => {
  const year = new Date().getFullYear();
  const screens = useBreakpoint();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '6px',
        flexWrap: 'wrap',
      }}
    >
      <div style={{ whiteSpace: 'nowrap' }}>
        <span>© {year}</span>{' '}
        <a
          className="social-link"
          href="https://nextgis.com"
          target="_blank"
          rel="noreferrer"
        >
          NextGIS
        </a>
      </div>
      <div style={{ display: 'flex', gap: '6px', flexWrap: 'nowrap' }}>
        <a
          className="social-link"
          href="https://t.me/nextgis_talks"
          target="_blank"
          rel="noreferrer"
        >
          <TelegramIcon />
        </a>

        <a href="https://twitter.com/nextgis" target="_blank" rel="noreferrer">
          <TwitterOutlined style={{ fontSize: 18 }} />
        </a>

        <a
          href="https://ru.linkedin.com/company/nextgis"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedinFilled style={{ fontSize: 18 }} />
        </a>

        <a href="https://github.com/nextgis/" target="_blank" rel="noreferrer">
          <GithubOutlined style={{ fontSize: 18 }} />
        </a>

        <a
          href="https://www.youtube.com/@nextgis_channel"
          target="_blank"
          rel="noreferrer"
        >
          <YoutubeFilled style={{ fontSize: 18 }} />
        </a>

        <a className="social-link" href="mailto:info@nextgis.com">
          {!screens.lg ? (
            <MailFilled style={{ fontSize: 18 }} />
          ) : (
            <span>info@nextgis.com</span>
          )}
        </a>
      </div>
    </div>
  );
};

const FooterContent: React.FC = () => {
  const { t, i18n } = useTranslation();

  const isRuLanguage = i18n.language === 'ru';
  return (
    <div
      style={{
        display: 'flex',
        gap: '8px',
        justifyContent: 'space-between',
        fontSize: 13,
      }}
    >
      {isRuLanguage ? <ContactsRu /> : <ContactsEn />}

      <div
        style={{
          display: 'flex',
          gap: '4px 20px',
          textDecoration: 'underline',
          flexWrap: 'wrap',
          alignContent: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <a
          style={{
            color: 'rgba(255, 255, 255, 0.4)',
            textDecoration: 'underline',
          }}
          href={getTermsUrl(i18n.language)}
        >
          {t('footer.terms')}
        </a>
        <a
          style={{
            color: 'rgba(255, 255, 255, 0.4)',
            textDecoration: 'underline',
          }}
          href={getConfUrl(i18n.language)}
        >
          {t('footer.conf')}
        </a>
      </div>
    </div>
  );
};

export { FooterContent };
