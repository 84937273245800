import './orderSubComponents.css';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Clipboard } from '@nextgis/utils';
import {
  Alert,
  Button,
  Descriptions,
  Form,
  Grid,
  Input,
  Modal,
  Tooltip,
} from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { showNotification } from '../../customNotifications';
import { useApi } from '../../providers/ServerApiProvider';
import { getDocsUrl, getFileDownloadurl } from '../../routes/apiRoutes';
import { appRoutes } from '../../routes/appRoutes';
import { toolBoxStore } from '../../store/store';
import { isInputPassword } from '../../utils/misc';
import { countDecimals, isUrl } from '../../utils/misc';
import { serializeErrorDescription } from '../../utils/orderUtils';
import { SpinnerPage } from '../SpinnerPage';

import type { Order } from '../../pages/Orders';
import type { DescriptionsProps } from 'antd';
import type { ReactNode } from 'react';

const formatDate = (inputDate: string, language: string) => {
  const date = new Date(inputDate);
  return date.toLocaleDateString(language, {
    day: '2-digit', // or numeric?
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    // hour12: true,
  });
};

type CombinedInput = {
  name: string;
  title: string;
  type: string;
  value: string;
};

type IconType = {
  [key: string]: ReactNode;
};

const iconMapping: IconType = {
  FAILED: <CloseCircleOutlined style={{ color: 'red' }} />,
  DENIED: <ExclamationCircleOutlined style={{ color: 'red' }} />,
  ACCEPTED: <ClockCircleOutlined style={{ color: '#8e9e26' }} />,
  STARTED: <LoadingOutlined style={{ color: '#8e9e26' }} />,
  SUCCESS: <CheckOutlined style={{ color: 'green' }} />,
};

const getIcon = (status: string): ReactNode => {
  if (iconMapping[status]) {
    return iconMapping[status];
  }
  return <InfoCircleOutlined />;
};

const getToolName = (operationId: string | undefined): string | null => {
  if (!operationId) {
    return null;
  }

  const result = toolBoxStore.publicTools.find(
    (tool) => tool.operation_id === operationId,
  );

  if (!result) {
    return null;
  }
  return result.name;
};

////////////////////////////////////////////////////////////////

const StatusFilterLabel: React.FC<{ status: string }> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', gap: '6px' }}>
      {status !== 'all' && getIcon(status)}
      {t(`orders.select_options.${status}`)}
    </div>
  );
};

////////////////////////////////////////////////////////////////

const FileResult: React.FC<{ value: any }> = ({ value }) => {
  //
  return (
    <Button
      type="primary"
      icon={<DownloadOutlined />}
      href={value.url}
      target="_blank"
    >
      {value.alias || value.name}
    </Button>
  );
};

////////////////////////////////////////////////////////////////

const StringResult: React.FC<{ value: any }> = ({ value }) => {
  const innerText = value.val;

  const isResultUrl = isUrl(value.val);

  const { t } = useTranslation();
  const [copyText, setCopyText] = useState(t('operation.copyTT'));

  return (
    <Paragraph>
      <pre style={{ margin: 0 }}>
        {value.alias && (
          <span style={{ color: 'rgba(0, 0, 0, 0.65) ' }}>
            <i>{value.alias}</i>:{' '}
          </span>
        )}
        {isResultUrl ? (
          <a className="link-result" target="blank" href={value.val}>
            {value.val}
          </a>
        ) : (
          <Tooltip title={copyText}>
            <span
              onMouseLeave={() => {
                setTimeout(() => {
                  setCopyText(t('operation.copyTT'));
                }, 100);
              }}
              onClick={() => {
                Clipboard.copy(innerText || '');
                setTimeout(() => {
                  setCopyText(t('operation.copiedTT'));
                }, 100);
              }}
              className={
                value.alias ? 'string-result-highlited' : 'string-result'
              }
            >
              {value.val}
            </span>
          </Tooltip>
        )}
      </pre>
    </Paragraph>
  );
};

////////////////////////////////////////////////////////////////

const renderResult = ([_key, value]: [key: string, value: any]) => {
  if (value.type === 'file') {
    return <FileResult value={value} />;
  } else if (value.type === 'string') {
    return <StringResult value={value} />;
  } else {
    return <div>{JSON.stringify(value)}</div>;
  }
};

const OrderResults: React.FC<{ order: Order }> = ({ order }) => {
  const { t } = useTranslation();

  if (!order.results) return <div>{t('orders.resultError')}</div>;

  const resultsArray = Object.entries(order.results);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: '6px',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          gap: '12px',
          flexWrap: 'wrap',
        }}
      >
        <div style={{ marginBottom: '8px' }}>{t('orders.results')}</div>
        <ul
          style={{
            maxWidth: '700px',
            paddingInlineStart: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            listStyleType: 'none',
          }}
        >
          {resultsArray.map((result: any, id: number) => (
            <li key={id}>{renderResult(result)}</li>
          ))}
        </ul>
      </div>

      <div
        style={{
          display: 'flex',
          gap: '6px',
          flexWrap: 'wrap',
          flexGrow: 1, // to move buttons to right side when line row breaks
          justifyContent: 'flex-end',
        }}
      ></div>
    </div>
  );
};

////////////////////////////////////////////////////////////////

const OrderLabel: React.FC<{ order: Order }> = ({ order }) => {
  const { t, i18n } = useTranslation();

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <span>{getIcon(order.status)} &nbsp;&nbsp;</span>
        <span style={{ color: '#9e9e9e', fontSize: 12 }}>
          {formatDate(order.created_at, i18n.language)}
        </span>{' '}
        &nbsp;&nbsp;
        <span style={{ fontSize: 14 }}>
          {getToolName(order?.parameters?.operation_name) ||
            order?.parameters?.operation_name ||
            t('orders.toolNameError')}
        </span>
      </div>
    </div>
  );
};

////////////////////////////////////////////////////////////////

const PasswordInputInfo: React.FC<{ value: string }> = ({ value }) => {
  return (
    <Input.Password
      style={{
        borderColor: '#d9d9d9',
        minWidth: '200px',
        caretColor: 'transparent',
        maxWidth: `${value.length * 1.2}ex`,
      }}
      contentEditable={false}
      value={value}
    />
  );
};

////////////////////////////////////////////////////////////////

const SupportButton: React.FC<{ order: Order }> = ({ order }) => {
  const { t } = useTranslation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const { email } = toolBoxStore.user;

  const serverApi = useApi();

  const [isSupportModalOpen, setSupportIsModalOpen] = useState(false);
  const [pending, setPending] = useState(false);

  const [form] = Form.useForm();
  const { TextArea } = Input;

  const showSupportModal = () => {
    setSupportIsModalOpen(true);
  };

  const handleSupportCancel = () => {
    setSupportIsModalOpen(false);
  };

  form.submit = async () => {
    try {
      setPending(true);
      const comment = form.getFieldValue('userComment') || '';
      await serverApi.postSupport(order.guid, comment);
      showNotification(
        t('orders.contactSupportSuccessHeader'),
        t('orders.contactSupportSuccessBody'),
        CheckOutlined,
        'green',
      );
      setPending(false);
    } catch (error) {
      console.log('Could not send support mesaage', error);
      setPending(false);
      showNotification(t('orders.contactSupportError'), '', CloseOutlined);
    }
  };

  return toolBoxStore.deployMode === 'cloud' ? (
    <>
      <Button
        icon={!screens.md && <SupportAgentIcon />}
        onClick={showSupportModal}
      >
        {screens.md && t('orders.contactSupport')}
      </Button>
      <Modal
        width="800px"
        title={
          <div
            style={{
              paddingRight: '22px',
              fontWeight: 500,
              fontSize: '18px',
              display: 'flex',
              gap: '20px',
            }}
          >
            <SupportAgentIcon
              style={{ fontSize: 48, color: 'rgb(0, 112, 197)' }}
            />
            <div>
              <span style={{ display: 'block' }}>
                {t('orders.contactSupportLabel')}
              </span>
              <span style={{ display: 'block' }}>{order.guid}</span>
            </div>
          </div>
        }
        open={isSupportModalOpen}
        onCancel={handleSupportCancel}
        footer={null}
      >
        <p
          style={{
            paddingLeft: '0px',
            fontSize: '16px',
            color: 'rgba(0, 0, 0, 0.65)',
          }}
        >
          {t('orders.contactSupportDescription', { email })}
        </p>
        <Form
          name="contactSupport"
          form={form}
          disabled={pending}
          layout="vertical"
          autoComplete="on"
          onSubmitCapture={() => {
            setSupportIsModalOpen(false);
          }}
        >
          <Form.Item
            label={
              <span style={{ fontSize: '16px', marginBottom: '4px' }}>
                {t('orders.contactSupportTextArealabel')}
              </span>
            }
            name="userComment"
          >
            <TextArea allowClear style={{ fontSize: '16px' }} rows={4} />
          </Form.Item>

          <Form.Item
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 0,
            }}
          >
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              loading={pending}
            >
              {t('orders.contactSupportOk')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  ) : null;
};

////////////////////////////////////////////////////////////////

const OrderInputsInfo: React.FC<{ order: Order }> = ({ order }) => {
  const { t } = useTranslation();
  const serverApi = useApi();
  const [loading, setLoading] = useState(true);
  const [inputsInfo, setInputsInfo] = useState<CombinedInput[]>([]);

  useEffect(() => {
    const inputs = Object.keys(order.parameters?.inputs);

    const fetchInputsInfo = async (order: Order) => {
      try {
        const res = await serverApi.getToolInputs(
          order?.parameters?.operation_name,
        );
        if (res.data) {
          const combinedInputsData = res.data.map((i: any) => {
            return {
              name: i.name,
              title: i.title,
              type: i.type,
              value: order?.parameters.inputs[i.name],
            };
          });
          setInputsInfo(combinedInputsData);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    if (inputs) {
      fetchInputsInfo(order);
    }
  }, [order, serverApi]);

  const getNormalizedInputValue = (input: any) => {
    // ???????????
    if (input.value === undefined || input.value === '') {
      return <i style={{ color: '#9e9e9e' }}>{t('orders.inputs.noInput')}</i>;
    }

    switch (input.type) {
      case 'string':
        return isInputPassword(input) ? (
          <PasswordInputInfo value={input.value} />
        ) : (
          <span>{input.value}</span>
        );
      case 'float':
        return (
          <span>
            {countDecimals(Number(input.value)) === 0
              ? Number(input.value).toFixed(1)
              : input.value}
          </span>
        );

      case 'boolean':
        return (
          <span style={{ color: 'rgb(0, 112, 197)' }}>
            {input.value ? t('orders.inputs.true') : t('orders.inputs.false')}
          </span>
        );
      case 'file':
        // needs a test!
        return input.value.name ? (
          <a href={getFileDownloadurl(input.value.name)}>
            {t('orders.inputs.download')}
          </a>
        ) : (
          <span>{t('orders.inputs.downloadError')}</span>
        );

      default:
        return JSON.stringify(input.value);
    }
  };

  const getNormalizedInputLabel = (input: any) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <span>{input.title}</span>
        <span style={{ flexGrow: 1, textAlign: 'end' }}>
          <i>
            {t(`orders.inputs.${input.type}`)}
            {isInputPassword(input) ? ` ${t('orders.inputs.password')}` : ''}
          </i>
        </span>
      </div>
    );
  };

  const inputsDescriptions: DescriptionsProps['items'] = inputsInfo.map(
    (input: any, i: number) => ({
      key: i,
      label: getNormalizedInputLabel(input),
      children: getNormalizedInputValue(input),
    }),
  );

  if (!order.parameters.inputs)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span>{t('orders.parametersError')}</span>{' '}
      </div>
    );

  return loading ? (
    <SpinnerPage style={{ marginTop: '20px' }} />
  ) : (
    <div
      style={{
        fontSize: 16,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <span style={{ lineHeight: 2 }}>{t('orders.parameters')}</span>
      </div>

      <Descriptions
        style={{ marginTop: '8px' }}
        contentStyle={{
          width: '60%',
        }}
        size="small"
        bordered
        items={inputsDescriptions}
        column={1}
      />
    </div>
  );
};

////////////////////////////////////////////////////////////////

const getReuseParametersUrl = (order: Order) => {
  const toolUrl = `../${appRoutes.operation}/${order.parameters.operation_name}`;

  const inputs = Object.entries(order.parameters.inputs);
  const inputsPrefill = `?${inputs
    .map(([key, value]) => {
      if (typeof value === 'object') {
        return key + '=' + encodeURIComponent(value.name);
      } else {
        return key + '=' + encodeURIComponent(value);
      }
    })
    .join('&')}`;

  return toolUrl + inputsPrefill;
};

////////////////////////////////////////////////////////////////

const OrderDetails: React.FC<{ order: Order }> = ({ order }) => {
  const { t, i18n } = useTranslation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const navigate = useNavigate();

  const handleReuseParameters = () => {
    navigate(getReuseParametersUrl(order));
  };

  const [copyText, setCopyText] = useState(t('orders.copyGUID'));

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '6px',
          marginBottom: '10px',
          fontSize: 16,
        }}
      >
        {order.status === 'SUCCESS' ? (
          <OrderResults order={order} />
        ) : (
          <span style={{ lineHeight: 1.9 }}>{order.status}</span>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '6px',
          }}
        >
          {toolBoxStore.appMode.deploy === 'cloud' && (
            <Button
              type="primary"
              href={getDocsUrl(i18n.language, order.parameters.operation_name)}
              target="_blank"
            >
              {t('orders.docLink')}
            </Button>
          )}

          {order?.parameters?.inputs && (
            <a
              href={getReuseParametersUrl(order)}
              onClick={(e) => e.preventDefault()}
            >
              <Button
                icon={
                  !screens.md && <RedoOutlined style={{ fontSize: '16px' }} />
                }
                onClick={handleReuseParameters}
                type="primary"
              >
                {screens.md && t('orders.reuseParameters')}
              </Button>
            </a>
          )}
          <SupportButton order={order} />
        </div>
      </div>

      {order.status === 'FAILED' && (
        <Alert
          type="error"
          message={
            <>
              <span>{t('orders.errorMessagePrefix')}</span>
              <br />
              {order.avral_error
                ? serializeErrorDescription(order.avral_error, i18n.language)
                : t('orders.undefinedError')}
            </>
          }
          description={t('orders.errorMessageLabelNew')}
          showIcon
          style={{ margin: '12px 0px', whiteSpace: 'pre-line' }}
        />
      )}
      {order?.parameters?.inputs && <OrderInputsInfo order={order} />}
      <div
        style={{
          textAlign: 'right',
          height: '8px',
        }}
      >
        <Tooltip title={copyText}>
          <Button
            type="link"
            style={{
              border: 0,
              padding: '0px 6px',
              height: '10px',
              color: 'rgb(158, 158, 158)',
              fontSize: '12px',
              cursor: 'pointer',
            }}
            onBlur={() => {
              setCopyText(t('orders.copyGUID'));
            }}
            onClick={() => {
              Clipboard.copy(order.guid);
              setTimeout(() => {
                setCopyText(t('orders.copyGUIDdone'));
              }, 100);
            }}
          >
            {order.guid}
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

export { OrderLabel, OrderDetails, OrderResults, StatusFilterLabel };
