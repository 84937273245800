import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />;

const SpinnerPage: React.FC<{
  style?: React.CSSProperties;
}> = ({ style = {} }) => {
  return (
    <Spin
      style={{
        marginTop: '20%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        ...style,
      }}
      indicator={antIcon}
    />
  );
};

export { SpinnerPage };
