import React from 'react';

const VKIcon: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        width: '20px',
        height: '20px',
      }}
    >
      <svg role="img" className="social-links__icon vkontakte-icon">
        <use xlinkHref="/static/social_icons.svg#vkontakte"></use>
      </svg>
    </div>
  );
};

export { VKIcon };
