export type AppRouteKey =
  | 'mainPage'
  | 'aboutPage'
  | 'operation'
  | 'pricingPage'
  | 'ordersPage'
  | 'other';

const appRoutes: Record<AppRouteKey, string> = {
  mainPage: '/',
  aboutPage: '/about',
  operation: 'operation',
  pricingPage: '/pricing',
  ordersPage: '/orders',
  other: '/404',
};

const getAppRoute = (key: AppRouteKey): string => {
  return appRoutes[key] || appRoutes.other;
};

export { appRoutes, getAppRoute };
