import { MenuOutlined } from '@ant-design/icons';
import { Button, Drawer, Grid, Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { appRoutes, getAppRoute } from '../routes/appRoutes';
import { toolBoxStore } from '../store/store';

import { Logo } from './Logo';
import { LogoSmall } from './LogoSmall';
import { UserBlock } from './UserBlock';

import type { AppRouteKey } from '../routes/appRoutes';
import type { TabsProps } from 'antd';

const VerticalLDivider: React.FC = () => (
  <div
    style={{ height: '36px', width: '2px', backgroundColor: '#b4d0ea' }}
  ></div>
);

const Title: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <a
      href={appRoutes.mainPage}
      onClick={(e) => {
        e.preventDefault();
        navigate(appRoutes.mainPage);
      }}
    >
      <div
        style={{
          fontSize: '16px',
          fontWeight: 500,
          color: '#0070c5',
        }}
      >
        {t('navigation.title')}
      </div>
    </a>
  );
};

const { useBreakpoint } = Grid;

const HeaderContent: React.FC = observer(() => {
  const { t } = useTranslation();

  const items: TabsProps['items'] =
    toolBoxStore.deployMode === 'cloud'
      ? [
          {
            key: 'mainPage',
            label: t('navigation.main'),
            id: '/',
          },
          {
            key: 'aboutPage',
            label: t('navigation.about'),
            id: '/about',
          },
          {
            key: 'pricingPage',
            label: t('navigation.pricing'),
            id: '/pricing',
          },
        ]
      : [
          {
            key: 'mainPage',
            label: t('navigation.main'),
            id: '/',
          },
        ];

  const screens = useBreakpoint();
  const location = useLocation();

  const activeTab = items.find((item) => item.id === location.pathname);
  const activeKey = activeTab?.key;

  const navigate = useNavigate();

  const onTabClick = (key: string) => {
    navigate(getAppRoute(key as AppRouteKey));
    setDrawerOpened(false);
  };

  const [drawerOpened, setDrawerOpened] = useState(false);

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
        }}
      >
        {!screens.lg ? (
          <>
            <Button
              type="text"
              style={{ backgroundColor: 'transparent' }}
              icon={
                <MenuOutlined
                  style={{
                    paddingTop: '1px',
                    fontSize: 20,
                    color: '#aec8e0',
                  }}
                />
              }
              onClick={() => setDrawerOpened(true)}
            />
            <LogoSmall />
          </>
        ) : (
          <Logo />
        )}
        <VerticalLDivider />
        <Title />
      </div>

      <Drawer
        width={158}
        placement={'left'}
        closable={false}
        open={drawerOpened}
        onClose={() => setDrawerOpened(false)}
        styles={{
          body: {
            paddingLeft: 0,
            background: `linear-gradient(to right, white 99.5%, #d3e3f2 0.5%)`,
          },
        }}
      >
        <Tabs
          tabPosition="left"
          activeKey={activeKey}
          items={items}
          onTabClick={onTabClick}
          tabBarGutter={14}
          tabBarStyle={{
            minWidth: '110px',
          }}
          moreIcon={null}
        />
      </Drawer>

      {screens.lg && (
        <Tabs
          activeKey={activeKey}
          items={items}
          onTabClick={onTabClick}
          tabBarGutter={14}
          tabBarStyle={{
            display: 'flex',
            height: '50px',
            alignItems: 'center',
            marginBottom: 0,
            border: 'none',
          }}
          moreIcon={null}
        />
      )}

      <UserBlock />
    </>
  );
});

export { HeaderContent };
