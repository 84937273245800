import React from 'react';
import { useNavigate } from 'react-router';

import { appRoutes } from '../routes/appRoutes';

const LogoSmall: React.FC = () => {
  const navigate = useNavigate();

  return (
    <a
      href={appRoutes.mainPage}
      onClick={(e) => {
        e.preventDefault();
        navigate(appRoutes.mainPage);
      }}
    >
      <div style={{ display: 'flex', width: '20px', height: '20px' }}>
        <svg
          version="1.1"
          id="svg2"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="3.2 1.9 20.1 20.5"
          xmlSpace="preserve"
          className="logo logo--mini hidden-md-and-up"
        >
          <g transform="translate(0,-924.36218)">
            <path
              d="M15.5,939.3l-1.6,2.3l3.4,5.2
            l5.9,0l-3.4-4.8l-3.1-4.5L15.5,939.3z"
              className="logo_small__item--light"
            ></path>{' '}
            <path
              d="M8.9,946.8l7.2-10.2l-7.2-10.2
            H3.2l7.2,10.3l-7.1,10.2H8.9z"
              className="logo_small__item--dark"
            ></path>{' '}
            <path
              d="M15.8,928.9l-1.9,2.7l2.8,4
            l6.5-9.3h-2.8h-2.8L15.8,928.9z"
              className="logo_small__item--light"
            ></path>
          </g>
        </svg>
      </div>
    </a>
  );
};

export { LogoSmall };
