import '../../operationForm.css';
import { Checkbox } from 'antd';
import React from 'react';

import type { toolInput } from '../../../interfaces';
import type { FormInstance } from 'antd';

const BoolInput: React.FC<{
  input: toolInput;
  form: FormInstance<any>;
  onValueChange?: (value: string) => void;
}> = ({ input, form, onValueChange }) => {
  return (
    <Checkbox
      name={input.name}
      checked={form.getFieldValue(input.name)}
      onChange={(e) => {
        form.setFieldValue(input.name, e.target.checked);
        if (onValueChange) {
          onValueChange(input.name);
        }
      }}
    />
  );
};

export { BoolInput };
