export const initChatra = (locale) => {
  window.ChatraID = 'TqbMGvKSEXA4StnLB';
  window.ChatraGroupID = 'hT53JYbKat4JBZTLv';
  window.ChatraSetup = {
    language: locale,
  };

  const c = 'Chatra';
  const s = document.createElement('script');
  window[c] =
    window[c] ||
    function () {
      (window[c].q = window[c].q || []).push(arguments);
    };
  s.async = true;
  s.src =
    (document.location.protocol === 'https:' ? 'https:' : 'http:') +
    '//call.chatra.io/chatra.js';
  if (document.head) document.head.appendChild(s);
};

/** @function
 * @param {string} username
 * @param {string} email
 */
export const updateChatraUser = (username, email) => {
  window.Chatra('updateIntegrationData', {
    name: username,
    email: email,
  });
};

/** @function
 * @param {string | undefined} username
 * @param {string | undefined} email
 * @param {string} autoMessage
 * @param {Object} order
 *
 */
export const openPrefilledOnTaskChatra = (
  username,
  email,
  autoMessage,
  order = {},
) => {
  window.Chatra('updateIntegrationData', {
    name: username,
    email,
  });

  window.Chatra('openChat');

  setTimeout(() => {
    window.Chatra('sendAutoMessage', autoMessage);
  }, 50);
};

export const openChatra = () => {
  window.Chatra('openChat');
};
