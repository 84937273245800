import '../../operationForm.css';
import { Input } from 'antd';
import React from 'react';

import { isInputPassword } from '../../../utils/misc';

import type { toolInput } from '../../../interfaces';
import type { FormInstance } from 'antd';

const StringInput: React.FC<{
  input: toolInput;
  form: FormInstance<any>;
  onValueChange?: (name: string) => void;
}> = ({ input, form, onValueChange }) => {
  return isInputPassword(input) ? (
    <Input.Password
      name={input.name}
      value={form.getFieldValue(input.name)}
      placeholder={''}
      allowClear
      onChange={(e) => {
        form.setFieldValue(input.name, e.target.value);
        if (onValueChange) {
          onValueChange(input.name);
        }
      }}
      style={{
        maxWidth: '600px',
      }}
    />
  ) : (
    <Input
      name={input.name}
      value={form.getFieldValue(input.name)}
      placeholder={''}
      allowClear
      autoComplete="on"
      onChange={(e) => {
        form.setFieldValue(input.name, e.target.value);
        if (onValueChange) {
          onValueChange(input.name);
        }
      }}
      style={{
        maxWidth: '600px',
      }}
    />
  );
};

export { StringInput };
